var pawLibrary = {
	onResize: function()
	{

	},
	getUrlParameter: function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
	},
	setTables: function(table)
	{
		// init datatables
		$.each(table, function(key,row) {
			var oTable = $(row).DataTable({
				"lengthMenu": [[20, 25, 50, -1], [20, 25, 50, "All"]],
				"bSort" : false,
				"bPaginate" : true,
				"fnDrawCallback": function() {
					$('.product-status').click(function() {
						$.ajax({
							cache: false,
							url: './ajax/helpStatus.inc.php',
							success:function(data){
								$.fancybox(data, {
									padding:0,
									closeBtn: false
								});
							}
						});
					});

					$('.product-notes').click(function() {
						$('#helpNotes').trigger('click');
					});
				}
			});
			$('.dataTables_filter,.dataTables_info').remove();
			$('.dataTables_length').hide();
			$('#search_txt').keyup(function(){
			    oTable.search($(this).val()).draw();
			});
		});

		// Adjust Length of Rows
		$('#length_sel').on('change', function() {
			var value = $(this).val();
			$('.dataTables_length select').val(value).trigger('change');
		});

		// Filter
		$('#filter_sel').on('change', function() {
			var value = $(this).val();
			var url = window.location.href.split('?');
					url = url[0];
					url += "?filter=" + value;
			window.location = url;
		});

	},

	removeFromCart: function()
	{
		$('.basket button').click(function(e) {
			e.preventDefault();
			var form = $(this).parents('form');
			var id = form.find('input[name="category"]').val();
			var url = './ajax/removeFromCart.inc.php';
			$.ajax({
				type: 'POST',
        cache: false,
        url: url,
				data: {
					'id': id,
					'remove': 1
				},
        success:function(data){
            $.fancybox(data, {
            	padding:0,
							closeBtn: false,
							afterShow: function() {
								var yes = $('#yes');
								var no = $('#no');

								yes.click(function() {
									var value = $(this).val();
									var url = window.location.href.split('?');
											url = url[0];
											url += "?remove=" + id;
									window.location = url;
								});

								no.click(function() {
									$.fancybox.close();
								});
							}
            });
          }
      });
		});

	},

	setQty: function()
	{
		$('form.qty input[name="qty"]').on('blur', function() {
			var form = $(this).closest('form');
			form.submit();
		});
	},

	addToCart: function()
	{
		$('.item button').click(function(e) {
			e.preventDefault();
			var form = $(this).parents('form');
			var qty = form.find('input[name="qty"]').val();
			var category = form.find('input[name="category"]').val();
			var stock = form.find('input[name="stock"]').val();
			$.ajax({
        type: "POST",
        cache: false,
        url: './ajax/addToCart.inc.php',
        data: {
					'qty': qty,
					'category': category,
					'cart': 1,
					'stock' : stock
				},
        success:function(data){
            $.fancybox(data, {
            	padding:0,
							closeBtn: false,
							afterShow: function() {
								var continue_btn = $('#continue');
								continue_btn.click(function(e) {
									e.preventDefault();
									$.fancybox.close();
								});
							}
            });
						var total = $('#total-num').html();
								total = $.trim(total);
								total = parseInt(total);
								total += parseInt(qty);
						$('#total-num').html(total);
          }
      });
		});

		return false;
	},

	confirmOrder: function()
	{
		$('#confirm_btn').click(function(e) {
			e.preventDefault();
			// Save Notes
			var data = {notes: $('#notes_txt').val() }
			$.ajax({
				type: 'POST',
				url: './ajax/saveNotes.inc.php?init=savenotes',
				data: data
			}).done(function(noteId) {
				// Confirm Order
				$.ajax({
	        cache: false,
	        url: './ajax/confirmOrder.inc.php',
	        success:function(data){
						$.fancybox(data, {
							padding:0,
							closeBtn: false,
							afterShow: function() {
								$('#yes').click(function() {
									var url = window.location.href.split('?');
											url = url[0];
											url += "?confirm=true&note=" + noteId;
									window.location = url;
								});
								$('#no').click(function(e) {
									e.preventDefault();
									$.fancybox.close();
								});
							}
						});
					}
				});
			});

		});
	},

	setDialogBoxes: function() {
		// dialog boxes for helper text
		$('#helpNotes').click(function() {
			$.ajax({
				cache: false,
				url: './ajax/helpNotes.inc.php',
				success:function(data){
					$.fancybox(data, {
						padding:0,
						closeBtn: false
					});
				}
			});
		});

		$('#status i').click(function() {
			$.ajax({
				cache: false,
				url: './ajax/helpStockLevels.inc.php',
				success:function(data){
					$.fancybox(data, {
						padding:0,
						closeBtn: false
					});
				}
			});
		});
	},

	loadProducts: function()
	{
		var target = $('#products');
		var loader = $('#loader');

		target.css('visibility', 'hidden');
		$.ajax({
			url: './ajax/listProducts.inc.php?init=products'
		}).done(function(response) {
			target.html(response);
			pawLibrary.addToCart();
			var table = $('table.datatable');
			pawLibrary.setTables(table);
			// filter if coming in from search
			var search = pawLibrary.getUrlParameter('search');
			if(search != '') {
				$('#search_txt').trigger('keyup');
			}
			loader.remove();
			target.css('visibility', 'visible');

			// dialogs
			pawLibrary.setDialogBoxes();

		});
	},

	addEditMembers: function() {
		$('#add-new-member-btn, .edit-btn').click(function() {
			var reloadOnClose = false;
			var editId = $(this).attr('data-member-id');
			$.fancybox({
				width:480,
				height:650,
				autoSize: false,
				href: './ajax/addMember.inc.php',
				type: 'ajax',
				padding:0,
				afterShow: function() {
					var validation = $('#validation');

					// EDIT
					if(editId > 0) {
						$('#member-dialog-title').html('Edit Member');
						$('#save-btn').html('Update Member');
						$.ajax({
							url: './ajax/getMember.inc.php?member=' + editId
						}).done(function(data) {
							var response = $.parseJSON(data);
							$('#member_id').val(response.id);
							$('#member_name_txt').val(response.name);
							$('#member_email_txt').val(response.email);
							$('#member_orders_sel').val(response.placeOrders);
							$('#member_prices_sel').val(response.showPrices);
							$('#member_password_txt').val('');
						});
					}

					$('#save-btn').click(function() {
						var valid = true;
						var member = {
							'id': $('#member_id').val(),
							'name': $('#member_name_txt').val(),
							'email': $('#member_email_txt').val(),
							'password': $('#member_password_txt').val(),
							'orders': $('#member_orders_sel').val(),
							'prices': $('#member_prices_sel').val()
						};

						if(member.email == '') {
							valid = false;
							$('#member_email_txt').addClass('required');
						}

						if((member.id == 0) && (member.password == '')) {
							valid = false;
							$('#member_password_txt').addClass('required');
						}

						validation.addClass('hidden');
						validation.empty();

						if(valid == true) {
							$.ajax({
								url: './ajax/createMember.inc.php',
								type: 'POST',
								data: { 'member': member }
							}).done(function(data) {
								var response = $.parseJSON(data);
								if(response.valid == 1) {
									validation.html('<div>Thank you, your member has been added</div>');
									reloadOnClose = true;
									$('#memberFrm,#save-btn,#cancel-btn').addClass('hidden');
								} else {
									validation.html('<div>This email is already in use.  Please try another one.</div>');
								}
								validation.removeClass('hidden');
							});
						}
					});
					$('#cancel-btn').click(function() {
						$.fancybox.close();
					});
				},
				afterClose: function() {
					if(reloadOnClose == true) {
						window.location.reload();
					}
				}
			});

		});

		$('.remove-btn').click(function() {
			var reloadOnClose = false;
			var removeId = $(this).attr('data-member-id');
			$.fancybox({
				width:480,
				height:500,
				href: './ajax/deleteMember.inc.php',
				type: 'ajax',
				padding:0,
				afterShow: function() {
					var validation = $('#validation');
					validation.addClass('hidden');
					validation.empty();
					$('#yes').click(function() {
						$.ajax({
							url: './ajax/deleteMember.inc.php?remove=' + removeId
						}).done(function() {
							validation.html('<div>Member removed</div>');
							validation.removeClass('hidden');
							reloadOnClose = true;
							$('#trade-remove-member-msg').addClass('hidden');
						});
					});
					$('#no').click(function() {
						$.fancybox.close();
					});
				},
				afterClose: function() {
					if(reloadOnClose == true) {
						window.location.reload();
					}
				}
			});
		});

	},

	init: function()
	{
		var items = $('form.item');
		var basket = $('form.basket');
		var setSearch = $('#search_txt');


		if($('#add-new-member-btn').length > 0) {
			pawLibrary.addEditMembers();
		}


		if($('#products').length == 0)
		{
			setSearch.keypress(function(e) {
				if(e.which == 13) {
						var url = $('base').attr('href');
						url += "trade/listings?search=" + setSearch.val();
						window.location = url;
				}
			});
		}

		if($('#products').length > 0)
		{
			pawLibrary.loadProducts();
		}

		if(basket.length > 0) {
			pawLibrary.setQty();
			pawLibrary.removeFromCart();
			pawLibrary.confirmOrder();
		}

		$('.btn-video').fancybox({
			padding: 0,
			margin: 0,
			maxWidth: 800,
	    maxHeight: 600,
      fitToView: false,
      width: '70%',
      height: '70%',
      autoSize: false,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
			type: 'iframe',
			afterLoad: function(e) {
				$(e.inner).find('iframe').attr({
					'webkitallowfullscreen': true,
					'mozallowfullscreen': true
				});
			},
			tpl: {
				closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;"><i class="fa fa-times" aria-hidden="true"></i></a>'
			}
		});
	}

}


$(window).on('load', function() {
	pawLibrary.init();
});
